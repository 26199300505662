import { Autocomplete, AutocompleteRenderInputParams, Box, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { ETransportStatus, EVehicleType, ITransportAutocompleteResponseDto } from "../../models/TransportModels";
import TransportService from "../../services/TransportService";
import AvatarComponent from '../Base/AvatarComponent/AvatarComponent';

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    type: EVehicleType;
    disableInactiveItems?: boolean;
    onChange?: (newValue: ITransportAutocompleteResponseDto | null) => void;
}
const TransportTypeAutocomplete = (props: IProps) => {
    const {
        label = 'Transports', required = false, onChange,
        value, type, disableInactiveItems = false
    } = props;

    const [data, setData] = useState<ITransportAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ITransportAutocompleteResponseDto | null>(null);

    useEffect(() => {
        if (EVehicleType.NONE !== type && EVehicleType[type] !== undefined) {
            (async () => {
                const [, response] = await TransportService.fetchTypeAutocomplete(type);
                if (response) {
                    const data: ITransportAutocompleteResponseDto[] = response.data.body;
                    setData(data);
                }
            })();
        }
    }, [type]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ITransportAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: ITransportAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ITransportAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', gap: '10px' }}>
                <Box width={'30px'}>
                    <AvatarComponent
                        data={item.avatar}
                        defaultValue={item.brand}
                    />
                </Box>

                <Box>
                    <Typography variant={'body2'}>{`${item.brand} ${item.model} ${item.year}`.toUpperCase()}</Typography>
                    <Typography variant='caption'>
                        <Divider />
                        {`${item.licensePlate} | #${item.transportNumber}`.toUpperCase()}
                    </Typography>
                </Box>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ITransportAutocompleteResponseDto) => {
        return `${item.brand} ${item.model} ${item.year} | ${item.licensePlate} | #${item.transportNumber}`.toUpperCase();
    }, []);

    const onBuildOptionKey = useCallback((item: ITransportAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    const getOptionDisabledHandler = useCallback((item: ITransportAutocompleteResponseDto) => {
        return disableInactiveItems
            ? item.status === ETransportStatus.INACTIVE
            : false;
    }, [disableInactiveItems]);

    return (
        <Autocomplete
            fullWidth
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionDisabled={getOptionDisabledHandler}
        />
    );
}
export default TransportTypeAutocomplete;