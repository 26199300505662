import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PaymentsIcon from '@mui/icons-material/Payments';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDateAndTimeFormat, getDateFormat, getTimeFormat } from "../../../helpers/dateHelper";
import { isEmpty, normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useLoad } from '../../../hooks/useLoad';
import { ECriteriaExpression, IBaseGridActionBtn, ICriteria, IDateAndTime, IFilter } from '../../../models/CommonModels';
import { ICustomerIdNameResponseDto } from '../../../models/CustomerModels';
import { ILoadResponseDto } from '../../../models/LoadModels';
import { ITransportShortResponseDto } from '../../../models/TransportModels';
import { IUserShortResponseDto } from '../../../models/UserModels';
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import LinkNav from '../../Base/LinkComponent/LinkNav';
import LoadChargeTypeManagementDialog from '../ChargeType/LoadChargeTypeManagementDialog';
import PalletTypeManagementDialog from '../PalletType/PalletTypeManagementDialog';
import ShipperConsigneeManagementDialog from '../ShipperConsignee/ShipperConsingeeManagementDialog';
import LoadBulkStatusDialog from './LoadBulkStatusDialog';
import LoadDialog from './LoadDialog';
import LoadFilter from './LoadFilter';

const displayTransportDetailsTooltip = (transport: ITransportShortResponseDto): string => {
    return `${transport.brand} ${transport.model} ${transport.year} | #${transport.transportNumber}`;
}

const LoadGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.grid);
    const { gridRefresh, stepRefresh } = useLoad();

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [palletTypeToggle, setPalletTypeToggle] = useState<boolean>(false);
    const [chargeTypeToggle, setChargeTypeToggle] = useState<boolean>(false);
    const [shipperConsigneeToggle, setShipperConsigneeToggle] = useState<boolean>(false);
    const [bulkStatusEditToggle, setBulkStatusEditToggle] = useState<boolean>(false);

    const [filter, setFilter] = useState<IFilter>();
    const [data, setData] = useState<ILoadResponseDto[]>([]);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        setFilter(filter);
        return LoadService.fetchAllByFilter(filter);
    }, []);

    const onInitData = useCallback((data: ILoadResponseDto[]) => {
        setData(data);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return LoadService.downloadReport(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onCustomerColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ICustomerIdNameResponseDto = params.value;
        return (
            <LinkNav
                tooltip={value.name.toUpperCase()}
                label={value.name.toUpperCase()}
                url={`/customers/${value.uuid}`}
            />
        );
    }, []);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const statusDate: string = getDateAndTimeFormat(row.statusDate, dateFormat, timeFormat);
        const value: string = params.value.toString().replaceAll('_', ' ');

        return (
            <Tooltip title={statusDate}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onItemsColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const shipper: number = row.totalShipperItems || 0;
        const consignee: number = row.totalConsigneeItems || 0;

        return (
            <Box sx={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onWeightColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const shipper: number = row.shipperWeight || 0;
        const consignee: number = row.consigneeWeight || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper} ${mass}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper} {mass}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee} ${mass}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee} {mass}</span>
                </Tooltip>
            </Box>
        );
    }, [mass, t]);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? currencyFormat(params.value) : '';
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onChargesColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? currencyFormat(params.value) : '';
        const row: ILoadResponseDto = params.row;
        const requiresProof: boolean = row.chargesRequiresProofs;
        const style: {} = requiresProof ? {} : { color: 'red' };
        const tooltip: string = requiresProof ? value : t('For this load there are charges for which the proof is missing. Please check the OTHER CHARGES section.');

        return (
            <Tooltip title={tooltip}>
                <span style={style}>{value}</span>
            </Tooltip>
        );
    }, [t]);

    const onShipDelDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: IDateAndTime[] | undefined = params.value;
        let dates: string = '';

        if (value) {
            dates = value.map((item) => {
                const date: string = getDateFormat(item.date, dateFormat);
                const time: string = getTimeFormat(item.time, timeFormat);
                return `${date}${time ? ` ${time}` : ''}`
            }).join(' | ');
        }

        return (
            <Tooltip title={dates}>
                <span>{dates}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onAddressesColumnRender = useCallback((params: GridRenderCellParams) => {
        const addresses: string[] | null = params.value;
        if (addresses === null || isEmpty(addresses)) {
            return '';
        }

        const values: string = addresses.join(' | ');

        return (
            <Tooltip title={values}>
                <span>{values}</span>
            </Tooltip>
        );
    }, []);

    const onUserColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const user: IUserShortResponseDto | null = params.value;

        if (row.assignLater) {
            return <span style={{ color: 'rgb(198, 40, 40)' }}>{t('ASSIGN LATER')}</span>;
        }

        if (user === null) {
            return '';
        }

        return (
            <LinkNav
                tooltip={user.fullName}
                label={user.fullName}
                url={`/users/${user.uuid}`}
            />
        );
    }, [t]);

    const onTransportsColumnRender = useCallback((params: GridRenderCellParams) => {
        const transports: ITransportShortResponseDto[] | null = params.value;
        if (transports === null || isEmpty(transports)) {
            return '';
        }

        return (
            <Box>
                {transports.map((transport, index) =>
                    <span key={`load-transport-${index}`}>
                        <LinkNav
                            tooltip={displayTransportDetailsTooltip(transport)}
                            label={`#${transport.transportNumber}`}
                            url={`/transports/${transport.uuid}`}
                        />
                        <span>{index < transports.length - 1 && ', '}</span>
                    </span>
                )}
            </Box>
        );
    }, []);

    const onIssueColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const done: number = row.numberOfDoneIssues || 0;
        const inProgress: number = row.numberOfInProgressIssues || 0;
        const open: number = row.numberOfOpenIssues || 0;
        const unresolved: number = row.numberOfUnresolvedIssues || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('OPEN: ')} ${open}`}>
                    <span style={{ color: '#ed6c02' }}>{open}</span>
                </Tooltip>

                <Tooltip title={`${t('IN PROGRESS: ')} ${inProgress}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{inProgress}</span>
                </Tooltip>

                <Tooltip title={`${t('DONE: ')} ${done}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{done}</span>
                </Tooltip>

                <Tooltip title={`${t('UNRESOLVED: ')} ${unresolved}`}>
                    <span style={{ color: 'rgb(198, 40, 40)' }}>{unresolved}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            hideable: false,
            getActions: onActionsColumnRender
        }, {
            field: 'favorite',
            headerName: '',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            hideable: false,
            renderCell: onFavoriteColumnRender
        }, {
            field: 'idno',
            headerName: t('ID#'),
            width: 110,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'customer',
            headerName: t('CUSTOMER'),
            width: 275,
            headerAlign: 'center',
            renderCell: onCustomerColumnRender
        }, {
            field: 'driver',
            headerName: t('DRIVER'),
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: onUserColumnRender
        }, {
            field: 'shipDates',
            headerName: t('SHIP DATE'),
            width: 170,
            headerAlign: 'center',
            renderCell: onShipDelDateColumnRender
        }, {
            field: 'delDates',
            headerName: t('DEL DATE'),
            width: 170,
            headerAlign: 'center',
            renderCell: onShipDelDateColumnRender
        }, {
            field: 'pickupAddresses',
            headerName: t('PICKUP ADDRESS'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: onAddressesColumnRender
        }, {
            field: 'deliveryAddresses',
            headerName: t('DELIVERY ADDRESS'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: onAddressesColumnRender
        }, {
            field: 'transports',
            headerName: t('TRANSPORTS'),
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: onTransportsColumnRender
        }, {
            field: 'items',
            headerName: t('STOPS'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: onItemsColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: onWeightColumnRender
        }, {
            field: 'dispatcher',
            headerName: t('DISPATCHER'),
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: onUserColumnRender
        }, {
            field: 'administrator',
            headerName: t('ADMINISTRATOR'),
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: onUserColumnRender
        }, {
            field: 'rate',
            headerName: t('RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'flatRate',
            headerName: t('FLAT RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'charges',
            headerName: t('O. CHARGES'),
            width: 115,
            headerAlign: 'center',
            align: 'center',
            renderCell: onChargesColumnRender
        }, {
            field: 'totalRate',
            headerName: t('TOTAL RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'issue',
            headerName: t('TASKS'),
            width: 180,
            headerAlign: 'center',
            renderCell: onIssueColumnRender
        }, {
            field: 'settlement',
            headerName: t('SETTLEMENT'),
            width: 115,
            headerAlign: 'center'
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 115,
            headerAlign: 'center',
            renderCell: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAmountColumnRender, onCreatedColumnRender,
        onCustomerColumnRender, onFavoriteColumnRender, onItemsColumnRender,
        onStatusColumnRender, onTooltipColumnRender, onWeightColumnRender, t,
        onShipDelDateColumnRender, onAddressesColumnRender, onIssueColumnRender,
        onUserColumnRender, onTransportsColumnRender, onChargesColumnRender
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onPalletTypeToggleHandler = useCallback(() => {
        setPalletTypeToggle(palletTypeToggle => !palletTypeToggle);
    }, []);

    const onChargeTypeToggleHandler = useCallback(() => {
        setChargeTypeToggle(chargeTypeToggle => !chargeTypeToggle);
    }, []);

    const onShipperConsigneeToggleHandler = useCallback(() => {
        setShipperConsigneeToggle(shipperConsigneeToggle => !shipperConsigneeToggle);
    }, []);

    const onBulkStatusDialogSubmit = useCallback(() => {
        stepRefresh();
        gridRefresh();
    }, [gridRefresh, stepRefresh]);

    const onBulkStatusEditDialogToggle = useCallback(() => {
        setBulkStatusEditToggle(bulkStatusEditToggle => !bulkStatusEditToggle);
    }, []);

    const onBulkStatusEditToggleHandler = useCallback((row: any, selectedRowsIds?: string) => {
        if (filter) {
            let filterTmp: IFilter = { ...filter };
            let criteriasTmp: ICriteria[] = [...filterTmp.criteria || []];

            if (selectedRowsIds) {
                const criteriasIds: ICriteria = {
                    property: "uuid",
                    value: selectedRowsIds,
                    expression: ECriteriaExpression.IN
                };
                criteriasTmp.push(criteriasIds);
            } else if (!isEmpty(criteriasTmp)) {
                criteriasTmp = criteriasTmp.filter(item => item.property !== 'uuid');
            }

            filterTmp.criteria = criteriasTmp;
            setFilter(filterTmp);
            onBulkStatusEditDialogToggle();
        }
    }, [filter, onBulkStatusEditDialogToggle]);

    const actionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Pallet management'),
            onClick: onPalletTypeToggleHandler,
            icon: <ViewTimelineOutlinedIcon />,
            key: 'pallet-action-btn',
            iconBtn: true
        }, {
            tooltip: t('Shipper & Consignee management'),
            onClick: onShipperConsigneeToggleHandler,
            icon: <HomeWorkIcon />,
            key: 'shipper-consignee-action-btn',
            iconBtn: true
        }, {
            tooltip: t('Charge type management'),
            onClick: onChargeTypeToggleHandler,
            icon: <PaymentsIcon />,
            key: 'charge-type-action-btn',
            iconBtn: true
        }];
    }, [onChargeTypeToggleHandler, onShipperConsigneeToggleHandler, onPalletTypeToggleHandler, t]);

    const secondActionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Bulk status edit'),
            onClick: onBulkStatusEditToggleHandler,
            icon: <SwapHorizontalCircleIcon />,
            iconBtn: true,
            disabled: isEmpty(data),
            key: 'bulk-status-edit-action-btn'
        }];
    }, [data, onBulkStatusEditToggleHandler, t]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        onDetailsActionBtnClick(params.id.toString());
    }, [onDetailsActionBtnClick]);

    return (
        <>
            <BaseCrudGrid
                gridId='load'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create load')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search load(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download load(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                actionBtns={actionBtns}
                secondActionBtns={secondActionBtns}
                onRowDoubleClick={onRowDoubleClickHandler}
                onInitData={onInitData}
            />

            {filterToggle &&
                <LoadFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <LoadDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }

            {palletTypeToggle &&
                <PalletTypeManagementDialog
                    open={palletTypeToggle}
                    onCloseBtnClick={onPalletTypeToggleHandler}
                />
            }

            {shipperConsigneeToggle &&
                <ShipperConsigneeManagementDialog
                    open={shipperConsigneeToggle}
                    onCloseBtnClick={onShipperConsigneeToggleHandler}
                />
            }

            {chargeTypeToggle &&
                <LoadChargeTypeManagementDialog
                    open={chargeTypeToggle}
                    onCloseBtnClick={onChargeTypeToggleHandler}
                />
            }

            {bulkStatusEditToggle && filter &&
                <LoadBulkStatusDialog
                    open={bulkStatusEditToggle}
                    filter={filter}
                    onSubmitBtnClick={onBulkStatusDialogSubmit}
                    onCloseBtnClick={onBulkStatusEditDialogToggle}
                />
            }
        </>
    );
}
export default LoadGrid;