import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Checkbox, FormControlLabel, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useLoad } from '../../../hooks/useLoad';
import { useNotification } from "../../../hooks/useNotification";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { ILoadOverviewResponseDto, ILoadRequestDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from '../../Base/CurrencyFieldComponent/CurrencyField';
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";
import CustomerDialog from '../../CustomerModule/Grid/CustomerDialog';

interface IProps {
    open: boolean;
    load?: ILoadOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadDialog = (props: IProps) => {
    const { open, load, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-form';

    const [loading, setLoading] = useState(false);
    const [customerToggle, setCustomerToggle] = useState<boolean>(false);
    const [customerRefresh, setCustomerRefresh] = useState<boolean>(false);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ILoadRequestDto>({
        defaultValues: {
            customerId: load ? load?.customer.uuid : '',
            assignLater: load ? load.assignLater : false,
            idno: load ? load?.idno || '' : '',
            rate: load ? load?.rate : NaN,
            flatRate: load ? load?.flatRate : NaN
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Load was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.create(data);
            if (response) {
                displayNotification({ message: t('Load was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ILoadRequestDto) => {
        const normalisedData: ILoadRequestDto = removeEmptyFields(data) as unknown as ILoadRequestDto;
        if (load) {
            updateData(load.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, load, updateData]);

    const validateCustomerField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('customerId', { validate: validateCustomerField });
    const onCustomerChangeHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setValue('customerId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onCustomerToggleHandler = useCallback(() => {
        setCustomerToggle(customerToggle => !customerToggle);
    }, []);

    const onSubmitCustomerHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('customerId', entityId);
            setCustomerRefresh(customerRefresh => !customerRefresh);
        }
    }, [setValue]);

    register('assignLater');
    const onAssignLaterChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;

        if (value) {
            setValue('idno', '');
        }

        setValue('assignLater', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateIdno = useCallback((value?: string) => {
        if (getValues('assignLater')) {
            return true;
        }

        if (!value || isBlank(value)) {
            return false;
        }

        return true;
    }, [getValues]);

    register('idno', { validate: validateIdno });
    const onIdnoChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('idno', event.target.value || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateRateField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('rate', { validate: validateRateField });
    const onRateChangeHandler = useCallback((value: number) => {
        setValue('rate', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('flatRate');
    const onFlatRateChangeHandler = useCallback((value: number) => {
        setValue('flatRate', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <CustomerAutocomplete
                            required
                            label={t('CUSTOMER')}
                            showAddress
                            disableInactiveItems
                            refresh={customerRefresh}
                            value={getValues('customerId')}
                            onChange={onCustomerChangeHandler}
                        />

                        <Tooltip title={t('CREATE CUSTOMER')}>
                            <IconButton onClick={onCustomerToggleHandler}>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <FormControlLabel
                        control={<Checkbox
                            checked={getValues('assignLater')}
                            onChange={onAssignLaterChangeHandler}
                        />}
                        label={t('ASSIGN LATER')}
                    />

                    <TextField
                        required={!getValues('assignLater')}
                        autoComplete='off'
                        label={t('ID#')}
                        value={getValues('idno')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 25 } }}
                        disabled={getValues('assignLater')}
                        onChange={onIdnoChangeHandler}
                    />

                    <Stack direction={'row'} spacing={2}>
                        <CurrencyField
                            label={t('RATE')}
                            required
                            allowNegative={false}
                            value={getValues('rate')}
                            onChange={onRateChangeHandler}
                        />

                        <CurrencyField
                            label={t('FLAT RATE')}
                            allowNegative={false}
                            value={getValues('flatRate') || NaN}
                            onChange={onFlatRateChangeHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        customerRefresh, getValues, handleSubmit, onAssignLaterChangeHandler, 
        onCustomerChangeHandler, onCustomerToggleHandler, onFlatRateChangeHandler, 
        onIdnoChangeHandler, onRateChangeHandler, onSubmit, t
    ]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t(`${load ? 'EDIT' : 'CREATE'} LOAD`)}
                maxWidth={'xs'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {customerToggle &&
                <CustomerDialog
                    open={customerToggle}
                    statusActive
                    onSubmitBtnClick={onSubmitCustomerHandler}
                    onCloseBtnClick={onCustomerToggleHandler}
                />
            }
        </>
    );
}
export default LoadDialog;