import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { ELoadStatus, ILoadItemStatusRequestDto, ILoadStatusRequestDto, ILoadStatusResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import StatusSelect from "../../../Base/StatusSelectComponent/StatusSelect";
import LoadItemsStatusDialog from "./LoadItemsStatusDialog";
import LoadStatusLegend from "./LoadStatusLegend";

const EXCLUSION_RULES: Map<ELoadStatus, string[]> = new Map<ELoadStatus, string[]>([
    [ELoadStatus.NONE, []],
    [ELoadStatus.OPEN, [ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.PENDING, [ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.IN_PROGRESS, [ELoadStatus.OPEN.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.DELIVERED, [ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.INVOICED, [ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.UNPAID, [ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString()]],
    [ELoadStatus.PAID, [ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString()]]
]);

const getDisableStatuses = (entity: ILoadStatusResponseDto): string[] => {
    const disabledItems: string[] = [...EXCLUSION_RULES.get(entity.status) || []];

    if (entity.status === ELoadStatus.OPEN) {
        const complete: boolean = entity.items && entity.users && entity.transports;
        if (!complete) {
            disabledItems.push(ELoadStatus.PENDING.toString());
        }
    }

    if (entity.status === ELoadStatus.IN_PROGRESS) {
        const complete: boolean = entity.billOfLading && entity.rateConfirmation;
        if (!complete) {
            disabledItems.push(ELoadStatus.DELIVERED.toString());
        }
    }

    if (entity.status === ELoadStatus.DELIVERED) {
        const complete: boolean = entity.requiresProofs;
        if (!complete) {
            disabledItems.push(ELoadStatus.INVOICED.toString());
        }
    }

    return disabledItems;
};

const statusData: string[] = Object.keys(ELoadStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadStatus[ELoadStatus.NONE])
    .map(key => key.toString());

const formId: string = 'load-status-form';

interface IProps {
    open: boolean;
    entityId: string;
    entity: ILoadStatusResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadStatusDialog = (props: IProps) => {
    const { open, entityId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();

    const [loading, setLoading] = useState<boolean>(false);
    const [itemStatusDialog, setItemStatusDialog] = useState<boolean>(false);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty } } = useForm<ILoadStatusRequestDto>({
        defaultValues: {
            status: entity.status,
            itemsStatus: undefined
        }
    });

    const onSubmit = useCallback((data: any) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateStatus(entityId, data);
            if (response) {
                displayNotification({ message: t('Load status was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                gridRefresh();
                stepRefresh();

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, t]);

    const itemStatusDialogToggle = useCallback(() => {
        setItemStatusDialog(itemStatusDialog => !itemStatusDialog);
    }, []);

    const validateStatusType = useCallback((value: ELoadStatus) => {
        return ELoadStatus[value] !== undefined && value !== ELoadStatus[ELoadStatus.NONE];
    }, []);

    register('status', { validate: validateStatusType });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        const status: ELoadStatus = event.target.value as ELoadStatus;
        setValue('status', status, {
            shouldValidate: true,
            shouldDirty: true
        });

        if (status === ELoadStatus.DELIVERED) {
            itemStatusDialogToggle();
        }
    }, [itemStatusDialogToggle, setValue]);

    const onBuildContent = useCallback(() => {
        const selectItems: string[] = [entity.status.toString().replaceAll('_', ' ')];
        const status: ELoadStatus = getValues('status');
        if (status !== ELoadStatus.NONE) {
            selectItems.push(status.toString().replaceAll('_', ' '));
        }

        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <StatusSelect
                        required
                        label={t('STATUS')}
                        data={statusData}
                        value={getValues('status')}
                        size='medium'
                        disableItems={getDisableStatuses(entity)}
                        onChange={onChangeStatusHandler}
                    />

                    <LoadStatusLegend selectItems={selectItems} />
                </Stack>
            </form>
        );
    }, [entity, getValues, handleSubmit, onChangeStatusHandler, onSubmit, t]);

    register('itemsStatus');
    const onLoadItemsStatusSubmitHandler = useCallback((items: ILoadItemStatusRequestDto[]) => {
        setValue('itemsStatus', items, {
            shouldValidate: true,
            shouldDirty: true
        });

        itemStatusDialogToggle();
    }, [itemStatusDialogToggle, setValue]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t('EDIT STATUS')}
                maxWidth={'xs'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {itemStatusDialog &&
                <LoadItemsStatusDialog
                    entityId={entityId}
                    open={itemStatusDialog}
                    onCloseBtnClick={itemStatusDialogToggle}
                    onSubmitBtnClick={onLoadItemsStatusSubmitHandler}
                />
            }
        </>
    );
}
export default LoadStatusDialog;