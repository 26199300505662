import { Checkbox, FormControlLabel, SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { ILoadUserRequestDto, ILoadUserResponseDto } from "../../../../models/LoadModels";
import { IAuthority } from "../../../../models/PermissionModels";
import { IUserAutocompleteResponseDto } from "../../../../models/UserModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import RoleSelect from "../../../RoleModule/RoleSelect";
import UserByTypeAutocomplete from "../../../UserModule/UserByTypeAutocomplete";

const excludeRoles: string[] = [
    IAuthority.ACCOUNTANT.toString(),
    IAuthority.OWNER.toString()
];

interface IProps {
    loadId: string;
    entity?: ILoadUserResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadUsersDialog = (props: IProps) => {
    const { open, loadId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-users-form';

    const [type, setType] = useState<IAuthority>(entity && entity.user.role
        ? entity.user.role
        : IAuthority.NONE
    );
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ILoadUserRequestDto>({
        defaultValues: {
            userId: entity ? entity.user.uuid : '',
            main: entity ? entity.main : true
        }
    });

    const updateData = useCallback((uuid: string, loadUser: ILoadUserRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateUser(loadId, uuid, loadUser);
            if (response) {
                displayNotification({ message: t('Load user was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                stepRefresh();
                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const createData = useCallback((loadUser: ILoadUserRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createUser(loadId, loadUser);
            if (response) {
                displayNotification({ message: t('Load user was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                stepRefresh();
                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onSubmit = useCallback((data: ILoadUserRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [entity, createData, updateData]);

    const validateUserField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('userId', { validate: validateUserField });
    const onUserChangeHandler = useCallback((value: IUserAutocompleteResponseDto | null) => {
        setValue('userId', value ? value.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('userId', '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setType(event.target.value as IAuthority);
    }, [setValue]);

    register('main');
    const onMainChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        setValue('main', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <RoleSelect
                        required
                        label={t('ROLE')}
                        value={type}
                        exclude={excludeRoles}
                        onChange={onTypeChangeHandler}
                    />

                    <UserByTypeAutocomplete
                        required
                        label={t('USER')}
                        type={type}
                        value={getValues('userId')}
                        disableInactiveItems
                        onChange={onUserChangeHandler}
                    />

                    <FormControlLabel
                        control={<Checkbox
                            checked={getValues('main')}
                            onChange={onMainChangeHandler}
                        />}
                        label={t('MAIN')}
                    />
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, onSubmit, onTypeChangeHandler,
        onUserChangeHandler, t, type, onMainChangeHandler
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${entity ? 'EDIT' : 'CREATE'} LOAD USER`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadUsersDialog;