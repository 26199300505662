import { Checkbox, FormControlLabel, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useLoad } from "../../../hooks/useLoad";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { ELoadStatus } from "../../../models/LoadModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";

const statusData: string[] = Object.keys(ELoadStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadStatus[ELoadStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    onClose: () => void;
}
const LoadFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useLoad();
    const { criterias } = useSelector((state: RootState) => state.loadSlice.grid);

    const [idno, setIdno] = useState<string>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [status, setStatus] = useState<ELoadStatus | undefined>(undefined);
    const [missingDoc, setMissingDoc] = useState<boolean>(false);

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || '');
        setCustomerId(getValueFromCriterias('customer.uuid', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as ELoadStatus || ELoadStatus.NONE);

        setMissingDoc(getValueFromCriterias('missingDoc', criterias) || false)
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIdno(event.target.value);
    }, []);

    const onChangeCustomerHandler = useCallback((customer: ICustomerAutocompleteResponseDto | null) => {
        setCustomerId(customer?.uuid || '');
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as ELoadStatus);
    }, []);

    const onChangeMissingDocHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMissingDoc(event.target.checked);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isBlank(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (customerId) {
            newCriterias.push({
                property: 'customer.uuid',
                value: customerId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (status) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (missingDoc) {
            newCriterias.push({
                property: 'missingDoc',
                value: missingDoc,
                expression: ECriteriaExpression.IS_TRUE
            });
        }

        return newCriterias;
    }, [customerId, idno, missingDoc, status]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('ID#')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={idno}
                        onChange={onChangeIdnoHandler}
                    />

                    <FormControlLabel
                        control={<Checkbox
                            checked={missingDoc}
                            onChange={onChangeMissingDocHandler}
                        />}
                        label={t('MISSING DOCUMENTS')}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <CustomerAutocomplete
                        label={t('CUSTOMER')}
                        value={customerId}
                        size='small'
                        onChange={onChangeCustomerHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        customerId, idno, missingDoc, onChangeCustomerHandler, onChangeIdnoHandler,
        onChangeMissingDocHandler, onChangeStatusHandler, status, t
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default LoadFilter;